import { css } from "aphrodite";
import { Map } from "immutable";
import PropTypes from "prop-types";
import { memo, Fragment, useCallback, useMemo, useRef } from "react";

import entityList from "components/Entities/EntityList";
import Title from "components/Entities/Page/Title";
import { useStubLinkClick } from "components/Entities/Stubs/useStubLinkClick";

import PodcastGridItem from "../../Podcast/PodcastGridItem";
import NoStubItemsMessage from "./NoStubItemsMessage";
import StubLink from "./StubLink";

import { SORT_ORDER_RECENT } from "constants/sort";
import loadPodcastsList from "sagas/pagination/lists/loadPodcastsList";
import { selectList } from "selectors/pagination";
import { selectSpecificPodcast } from "selectors/podcast";
import getPodcastName from "utils/entity/getPodcastName";
import getPodcastUrl from "utils/entity/getPodcastUrl";
import sendGAEvent from "utils/sendGAEvent";

import useReduxState from "hooks/useReduxState";
import { useStyles } from "hooks/useStyles";
import useWindowSize from "hooks/useWindowSize";

import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  link: {
    marginTop: ".8rem",
  },
  podcastList: {
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    margin: "0 -0.75rem -1rem",
    listStyle: "none",
    gap: "0.5rem",
    marginLeft: "0.625rem",
    [ScreenSizes.smAndAbove]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
  },
  podcast: {
    margin: 0,
    listStyle: "none",
    width: 128,
    height: 128,

    [ScreenSizes.mdAndAbove]: {
      width: 132,
      height: 132,
    },

    [ScreenSizes.lgAndAbove]: {
      width: 162,
      height: 162,
    },
    [ScreenSizes.xlAndAbove]: {
      width: 193.57,
      height: 193.57,
    },
  },
  mobileContainer: {
    display: "block",

    [ScreenSizes.mdAndAbove]: {
      display: "none",
    },
  },
};

const PodcastGridItemStyles = {
  podcastGridItem: {
    display: "block",
    maxWidth: 250,
    margin: "0 auto",
  },
};

const IMAGE_WIDTH_DESKTOP = "11.5rem";
const IMAGE_WIDTH_TABLE = "11.5rem";
const IMAGE_WIDTH_MOBILE = "22.8rem";

function getImageWidth(isMobile, isMedium) {
  if (isMobile) {
    return IMAGE_WIDTH_MOBILE;
  }
  if (isMedium) {
    return IMAGE_WIDTH_TABLE;
  }
  return IMAGE_WIDTH_DESKTOP;
}

const PodcastsStub = (props) => {
  const {
    clickEventAction,
    clickEventVars,
    disablePopup,
    dontRenderList,
    entity,
    entityLinkStyles,
    hideEmpty,
    inSidebar,
    linkLabel,
    listConfig,
    listKey,
    maxItems,
    noItemsMessage,
    onTabChange,
    rescore,
    scrollRef,
    showCount,
    showMore,
    showViewAll,
    sort,
    staticFilters,
    title,
    titleStyles,
    viewAllLink,
    viewAllTitle,
  } = props;
  const { styles } = useStyles(baseStyles, props);
  const { isWindowSizeOrLess } = useWindowSize();
  const isMedium = isWindowSizeOrLess("medium");
  const isMobile = isWindowSizeOrLess("small");

  const list = useReduxState(
    (state) => selectList(state, { key: listKey }),
    [listKey]
  );

  const count = useMemo(() => {
    if (showViewAll) {
      return isMedium ? list && list.get("total") : null;
    }

    return undefined;
  }, [showViewAll, isMedium, list]);

  const handleLinkClick = useStubLinkClick(onTabChange, scrollRef);

  const handlePodcastClick = useCallback(
    (position) => (podcast) => {
      if (clickEventAction) {
        sendGAEvent({
          action: "podcastLinkClicked",
          podcast_id: podcast.get("id"),
          podcast_name: getPodcastName(podcast),
          entity_id: podcast.get("id"),
          entity_type: "podcast",
          position,
          page: clickEventAction,
          ...clickEventVars,
        });
      }
    },
    [clickEventAction, clickEventVars]
  );

  const PodcastsList = useRef(
    entityList({
      key: listKey,
      sort,
      list_type: "podcasts_filtered",
      loadListAction: loadPodcastsList,
      entity_type: "podcast",
      pageSize: maxItems,
      staticFilters,
      getEntity: (state, id) => selectSpecificPodcast(state, id),
      noLoadingOverlay: true,
      loadingStyles: {
        noOverlay: {
          padding: "4rem 0",
          fontSize: "2rem",
        },
      },
      ...listConfig,
      ...(rescore
        ? {
            rescore,
          }
        : {}),
    })
  ).current;

  const imageWidth = getImageWidth(isMobile, isMedium);

  const renderItem = useCallback(
    (podcast, index) => (
      <li key={podcast.get("id")} className={css(styles.podcast)}>
        <PodcastGridItem
          podcast={podcast}
          styles={PodcastGridItemStyles}
          url={getPodcastUrl(podcast)}
          onClick={handlePodcastClick(index)}
          imageWidth={imageWidth}
          disablePopup={disablePopup}
          imgProps={{
            loading: "lazy",
            height: null,
          }}
          entityLinkStyles={entityLinkStyles}
        />
      </li>
    ),
    [
      styles.podcast,
      handlePodcastClick,
      imageWidth,
      disablePopup,
      entityLinkStyles,
    ]
  );

  if (list && list.get("total") === 0 && list.get("loaded")) {
    if (hideEmpty) {
      return null;
    }
    return (
      <Fragment>
        {title && (
          <Title
            title={title}
            TitleComponent="h2"
            styles={titleStyles}
            inSidebar={inSidebar}
          />
        )}
        <NoStubItemsMessage
          message={noItemsMessage || <span>None found.</span>}
        />
      </Fragment>
    );
  }

  if (dontRenderList) {
    return (
      <PodcastsList
        renderItem={renderItem}
        showMore={showMore}
        onMoreClick={handleLinkClick}
        dontRenderList
      />
    );
  }

  return (
    <Fragment>
      {title && (
        <Title
          title={title}
          onTabChange={showViewAll ? handleLinkClick : undefined}
          count={showCount ? count : undefined}
          TitleComponent="h2"
          link={viewAllLink}
          viewAllTitle={
            viewAllTitle ||
            (entity.get("title") && `Podcasts from ${entity.get("title")}`) ||
            "Podcasts"
          }
          styles={titleStyles}
          inSidebar={inSidebar}
        />
      )}
      <ul className={css(styles.podcastList)}>
        <PodcastsList
          renderItem={renderItem}
          showMore={showMore}
          onMoreClick={handleLinkClick}
        />
      </ul>
      {showViewAll && (
        <div className={css(styles.mobileContainer)}>
          <StubLink
            className={css(styles.link)}
            onClick={handleLinkClick}
            label={linkLabel}
          />
        </div>
      )}
    </Fragment>
  );
};

PodcastsStub.propTypes = {
  clickEventAction: PropTypes.string,
  clickEventVars: PropTypes.object,
  disablePopup: PropTypes.bool,
  dontRenderList: PropTypes.bool,
  entity: PropTypes.instanceOf(Map),
  entityLinkStyles: PropTypes.object,
  hideEmpty: PropTypes.bool,
  inSidebar: PropTypes.bool,
  linkLabel: PropTypes.string,
  listConfig: PropTypes.object,
  listKey: PropTypes.string.isRequired,
  maxItems: PropTypes.number,
  noItemsMessage: PropTypes.node,
  onTabChange: PropTypes.func,
  rescore: PropTypes.object,
  scrollRef: PropTypes.object,
  showBadges: PropTypes.bool,
  showCount: PropTypes.bool,
  showIsVerified: PropTypes.bool,
  showMore: PropTypes.bool,
  showTitles: PropTypes.bool,
  showViewAll: PropTypes.bool,
  sort: PropTypes.string,
  staticFilters: PropTypes.object,
  title: PropTypes.string,
  titleStyles: PropTypes.object,
  viewAllLink: PropTypes.string,
  viewAllTitle: PropTypes.string,
};

PodcastsStub.defaultProps = {
  clickEventAction: undefined,
  clickEventVars: {},
  disablePopup: null,
  dontRenderList: false,
  entity: null,
  entityLinkStyles: null,
  hideEmpty: false,
  inSidebar: false,
  linkLabel: "View All",
  listConfig: {},
  maxItems: 4,
  noItemsMessage: null,
  onTabChange: null,
  rescore: null,
  scrollRef: null,
  showBadges: false,
  showCount: true,
  showIsVerified: null,
  showMore: false,
  showTitles: false,
  showViewAll: true,
  sort: SORT_ORDER_RECENT,
  staticFilters: null,
  titleStyles: null,
  viewAllLink: null,
  viewAllTitle: null,
};

export default memo(PodcastsStub);
